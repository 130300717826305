<!--
 * @Author: LazyQ
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-24 16:55:59
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
</style>
<template>
  <basic-layout>
    <div class="play">游戏中心</div>
  </basic-layout>
</template>

<script>
import BasicLayout from "@/layouts/BasicLayout.vue";

export default {
  name: "play",
  components: {
    BasicLayout,
  },
};
</script>
